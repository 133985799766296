/**
 * List and manage saved queries.
 */
import { apiDelete, apiGet, apiPut } from '@/util/api';
import Icon from '@/icons/Icon.vue';
import PowerPointGenerator from '@/components/PowerPointGenerator/PowerPointGenerator.vue';

export default {
  name: 'Queries',
  components: {
    Icon,
    PowerPointGenerator,
  },
  data: () => ({
    loading: true,

    // Data
    series: [],
    tags: [],

    // Editing
    editingName: '',
    nameError: '',

    // Pagination configs
    currentPage: 1,
    perPage: 10,
    rows: 0,
  }),
  async created() {
    await this.getSeries();
    this.tags = (await apiGet('/tag')).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  },
  computed: {
    from: function() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    to: function() {
      return this.rows / this.perPage < this.currentPage
        ? this.rows
        : this.currentPage * this.perPage;
    },

    tableFields() {
      return [
        { key: 'name', label: this.$t('common_name') },
        { key: 'edit', label: '', class: 'table-user-control' },
        { key: 'analyse', label: '', class: 'table-user-control' },
        { key: 'recommend', label: '', class: 'table-user-control' },
        { key: 'delete', label: '', class: 'table-user-control' },
      ];
    },
  },
  watch: {
    currentPage: async function() {
      await this.getSeries();
    },
  },
  methods: {
    async getSeries() {
      this.loading = true;
      try {
        this.series = (
          await apiGet(
            `/series?skip=${this.perPage * (this.currentPage - 1)}&take=${
              this.perPage
            }`,
          )
        ).map(s => ({
          id: s.id,
          name: `${s.name}${
            s.series.indexOf('Manual') > -1
              ? ` (${this.$t('wheel_modal_load_manual_series_nb')})`
              : ''
          }`,
          series: s.series,
        }));
        this.rows = await apiGet('/series/count');
      } catch {
        this.$bvToast.toast(this.$t('queries_page_error_get_series'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      } finally {
        this.loading = false;
      }
    },

    async saveSeries(item, index, event) {
      event.preventDefault();
      const editedItem = { ...item };
      editedItem.name = this.editingName.trim();

      if (!editedItem.name) {
        this.nameError = this.$t('queries_page_error_blank_name');
        return;
      } else {
        this.nameError = '';
      }

      this.loading = true;
      try {
        await apiPut(`/series/${editedItem.id}`, editedItem);
        this.$bvToast.toast(
          this.$t('queries_page_success_change_name', [this.editingName]),
          {
            title: this.$t('common_success_title'),
            variant: 'success',
          },
        );
        this.$bvModal.hide(`nameModal${item.id}`);
        this.series[index].name = this.editingName;
      } catch {
        this.$bvToast.toast(this.$t('queries_page_error_change_name'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      } finally {
        this.loading = false;
      }
    },

    closeModal() {
      this.nameError = '';
    },

    async deleteSeries(item) {
      this.loading = true;
      try {
        await apiDelete(`/series/${item.id}`);
        this.$bvToast.toast(
          this.$t('queries_page_success_delete', [item.name]),
          {
            title: 'Success',
            variant: 'success',
          },
        );
        await this.getSeries();
      } catch {
        this.$bvToast.toast(this.$t('queries_page_error_delete', [item.name]), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      } finally {
        this.loading = false;
      }
    },

    recommend(series) {
      this.$router.push({ name: 'recommended', params: { series } });
    },
  },
};
