/**
 * FUBI definitions selector for PowerPoint generator.
 */

/* eslint-disable */
import Icon from '@/icons/Icon.vue';

export default {
  name: 'FUBIDefinitionsSelector',
  components: {
    Icon,
  },
  data() {
    return {
      definitions: [],
      loading: true,
    }
  },
  async created() {
    const glossary = this.$t('glossary');

    Object.keys(glossary).forEach(category => {
      Object.keys(glossary[category]).forEach(genre => {
        this.definitions.push({ ...glossary[category][genre], category, genre, selected: false });
      });
    });
    this.loading = false;
  },
  methods: {
    clickDefinition(def) {
      def.selected = !def.selected;
      this.$emit('change-definitions', { definitions: this.definitions.filter(d => d.selected) })
    },
  },
};
