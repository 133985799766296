/**
 * PowerPoint generator.
 */

/* eslint-disable */
import PptxGenJS from 'pptxgenjs';
import Icon from '@/icons/Icon.vue';
import QueriesContentSelector from './QueriesContentSelector.vue';
import FUBIDefinitionsSelector from './FUBIDefinitionsSelector.vue';
import { apiGet } from '@/util/api';
import Wheel from '@/components/Wheel/Wheel.vue';
import { capitalize } from 'lodash';
import axios from 'axios';

export default {
  name: 'PowerPointGenerator',
  components: {
    Wheel,
    Icon,
    QueriesContentSelector,
    FUBIDefinitionsSelector,
  },
  props: {
    series: Object,
    tags: Array,
  },
  data: function () {
    return {
      step: 1,

      generating: false,
      prepped: false,
      recommending: false,

      surveys: [],
      countries: [],
      contents: [],

      signedUrls: {},
      currentPage: 1,
      perPage: 8,
      rows: 0,
      selectedContent: [],
      selectedDefinitions: [],
      pptTitle: '',
      stepLabel: '',
      preanalyse: false,
      analysis: null,
      shouldGeneratePPT: false,
      shouldTriggerRecommendation: false,
    }
  },
  methods: {
    async getSurveys() {
      try {
        this.surveys = await apiGet('/survey');
      } catch {
        this.$bvToast.toast(this.$t('dashboard_surveys_get_error'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },

    async getCountries() {
      try {
        const data = await apiGet(`/survey/${this.surveys[0].id}/countries`);
        this.countries = data.countries;
        this.signedUrls = data.signedUrls;
      } catch (e) {
        this.$bvToast.toast(this.$t('survey_error_get_countries'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      }
    },

    changeContents(payload) {
      this.selectedContent = payload.contents;
    },
    changeDefinitions(payload) {
      this.selectedDefinitions = payload.definitions;
    },

    initAnalysis(shouldGeneratePPT, shouldTriggerRecommendation) {
      this.shouldGeneratePPT = shouldGeneratePPT;
      this.shouldTriggerRecommendation = shouldTriggerRecommendation;

      if (this.analysis && this.shouldGeneratePPT) {
        this.generatePPT();
      } else if (this.analysis && this.shouldTriggerRecommendation) {
        this.$refs.contentSelectorComp.setRecommended(this.analysis);
      } else {
        this.recommending = true;
        this.startGeneration();
      }
    },

    async analysisComplete(analysis) {
      this.analysis = await analysis;
      if (this.analysis && this.shouldGeneratePPT) {
        this.generatePPT();
      } else if (this.shouldTriggerRecommendation) {
        this.recommending = false;
        this.$refs.contentSelectorComp.setRecommended(analysis);
      }
    },

    async startGeneration() {
      this.generating = true;

      // STEP 1 - get stuff wheel needs to initialise
      try {
        await this.getSurveys();
        await this.getCountries();

        // STEP 2 - load wheel
        this.prepped = true;
      } catch {
        this.generating = false;
        this.step = 1;
        this.$bvToast.toast(
          this.$t('powerpoint_generation_fail'),
          {
            title: this.$t('common_error_title'),
            variant: 'danger',
          },
        );
      }
    },

    async generatePPT() {
      try {
        // STEP 3 - wait for analysis
        const analysis = this.analysis;
        // STEP 4 - build ppt
        let pres = new PptxGenJS({
          title: this.pptTitle,
        });

        pres.defineLayout({ name: 'custom', width: 26.66667, height: 15 });
        pres.layout = 'custom';

        const wheelGraphic = await this.svgToPng(analysis.svg, 1000, 1000, 'png')

        // Slide 1 - Overview and wheel graphic
        const slide1 = pres.addSlide();
        slide1.addText(this.$t('common_FUBI'), {
          x: 1.32,
          y: 1.18,
          w: 10.69,
          h: 1.57,
          fontSize: 85,
          fontFace: 'Times',
        });
        slide1.addText(this.$t('powerpoint_text_global_title'), {
          x: 1.32,
          y: 2.6,
          w: 10.69,
          h: 1.02,
          fontSize: 55,
          fontFace: 'Times',
        });
        slide1.addText(
          this.series.name,
          {
            x: 1.32,
            y: 4.65,
            w: 10.69,
            h: 1.03,
            fontSize: 36,
            fontFace: 'Arial',
          },
        );
        slide1.addText(analysis.caption, {
          x: 1.32,
          y: 6,
          w: 10.69,
          h: 1.02,
          fontSize: 30,
          fontFace: 'Arial',
        });
        slide1.addImage({
          path: wheelGraphic,
          x: 13.33,
          y: 1.38,
          w: 11.94,
          h: 12.24,
        });


        // Slide 2
        const slide2 = pres.addSlide();

        // Slide 2 pt 1 - fubi profile
        // Currently commented out as it displays data in wrong format

        const profile = [];
        slide2.addText(this.$t('common_FUBI'), {
          x: 1.32,
          y: 1.18,
          w: 10.69,
          h: 1.57,
          fontSize: 85,
          fontFace: 'Times',
        });
        slide2.addText(this.pptTitle, {
          x: 1.32,
          y: 2.6,
          w: 10.69,
          h: 1.02,
          fontSize: 55,
          fontFace: 'Times',
        });

        for (let a = 0; a < analysis.fubi_labels.length; a++) {
          let gHist = analysis.fubi_histogram_global[a].toFixed(1);
          if (gHist === '-0.0') gHist = '0.0';
          let hist = analysis.fubi_histogram[a].toFixed(1);
          if (hist === '-0.0') hist = '0.0';
          let histDiff = (analysis.fubi_histogram[a] - analysis.fubi_histogram_global[a]).toFixed(1);
          if (histDiff === '-0.0') histDiff = '0.0';
          profile.push([analysis.fubi_labels[a], gHist, hist, histDiff]);
        }

        profile.sort((a, b) => b[3] - a[3]);

        slide2.addTable([this.$t('powerpoint_profile_headers'), ...profile], {
          x: 1.32,
          y: 4.65,
          w: 10,
          fontFace: 'Arial',
        });

        // Slide 2 pt 2 - attitudes
        const attitudes = analysis.ATTITUDES
          .map(this.getAttitudeRow)
          .sort((a, b) => b[3] - a[3])
          .map(this.formatRow);

        slide2.addTable([this.$t('powerpoint_attitudes_headers'), ...attitudes], {
          x: 16,
          y: 4.65,
          w: 10,
          fontFace: 'Arial',
        });


        // Slide(s) 3 - affinities

        const affinities = analysis.AFFINITIES
          .filter(([name]) => name.search(/(NONE|OTHER)/) === -1)
          .map(this.getAffinityRow)
          .sort((a, b) => b[3] - a[3])
          .map(this.formatRow);

        const affinitiesChunk = _.chunk(affinities, 42);
        affinities.unshift();

        const affinitiesParams = {
          y: 1.5,
          w: 9,
          fontFace: 'Arial',
        };

        for (let i = 0; i < affinitiesChunk.length; i = i + 3) {
          const affinitiesSlide = pres.addSlide();
          affinitiesSlide.addTable(
            [this.$t('powerpoint_affinities_headers'), ...affinitiesChunk[i]],
            { ...affinitiesParams, x: 1, }
          );
          if (affinitiesChunk[i + 1]) {
            affinitiesSlide.addTable(
              [this.$t('powerpoint_affinities_headers'), ...affinitiesChunk[i + 1]],
              { ...affinitiesParams, x: 10, }
            );
          }
          if (affinitiesChunk[i + 2]) {
            affinitiesSlide.addTable(
              [this.$t('powerpoint_affinities_headers'), ...affinitiesChunk[i + 2]],
              { ...affinitiesParams, x: 19, }
            );
          }
        }


        // Slide 4 - demographic
        // NB these tables are not sorted by DIFF
        const slide4 = pres.addSlide();

        // SEGMENT
        // 'Segment' functionality not currently implemented in system
        // Brian confirmed leave out

        // GENDER
        // OK
        const gender = this.getQuestionRows(
          analysis["DEMOGRAPHICS"].find(d => d[0] === "GENDER")
        )
          .map(this.formatRow);

        slide4.addTable([this.$t('powerpoint_gender_headers'), ...gender], {
          x: 1.32,
          y: 1.18,
          w: 10.69,
          fontFace: 'Arial',
        });

        // AGE
        // Still not right in analysis... 🙄
        // Should be age ranges in the same way as above demographics
        // Instead it's just the average age

        // const age = this.getQuestionRows(analysis["AGE"][0])
        //   .map(this.formatRow);

        // slide4.addTable([this.$t('powerpoint_age_headers'), ...age], {
        //   x: 1.32,
        //   y: 4.65,
        //   w: 10.69,
        //   fontFace: 'Arial',
        // });


        // INCOME BAND
        // This cannot be done because the data only has global values, per-country.

        // All the categories are different and cannot be combined directly - £, €, $, etc depending on country
        // The 'lower', 'middle', and 'upper' bands are unknown. Different currencies could potentially be irrelevant
        // if these are known for each


        // LIFESTAGE
        // Leaving out (Brian/John confirmed)

        // EMPLOYMENT STATUS
        // OK
        const employment = this.getQuestionRows(analysis["DEMOGRAPHICS"].find(d => d[0] === "WORKING STATUS"))
          .map(this.formatRow);

        slide4.addTable([this.$t('powerpoint_employment_headers'), ...employment], {
          x: 13.33,
          y: 1.18,
          w: 10.69,
          fontFace: 'Arial',
        });


        // Slide(s) 5 - contents
        if (this.selectedContent.length > 0) {
          const selectedContent = this.selectedContent;
          const thumbnails = await apiGet(`/content/thumbnails?ids=${this.selectedContent.map(c => c.id).join(',')}`);
          for (let i = 0; i < selectedContent.length; i = i + 2) {
            const contentSlide = pres.addSlide();

            const imageA = thumbnails[selectedContent[i].id] && await axios.get(thumbnails[selectedContent[i].id], {
              responseType: 'arraybuffer'
            })
              .then(res => res)
              .catch(res => res);

            const imageParamsA = {
              x: '10%',
              y: '30%',
              w: 8,
              h: 6
            };

            if (imageA && imageA.status >= 200 && imageA.status < 300) {
              imageParamsA.data = 'image/jpeg;base64,' + this.arrayBufferToBase64(imageA.data);
            } else {
              imageParamsA.path = './thumb_placeholder.jpg';
            }

            const tagsA = selectedContent[i] ? selectedContent[i].tags.map(t => t.name).join(', ') : '';
            contentSlide
              .addText(this.$t('powerpoint_content_description'), { x: '10%', y: '10%', fontSize: 22 })
              .addText(selectedContent[i].name, {
                x: '10%', y: '20%', w: '40%',
                hyperlink: { url: selectedContent[i].url }
              })
              .addText(tagsA, {
                x: '10%',
                y: "25%",
                w: '35%'
              })
              .addImage(imageParamsA)
              .addText(selectedContent[i].description, {
                x: '10%',
                y: 11.5,
                w: '40%',
              });
            if (selectedContent[i + 1]) {
              const imageB = thumbnails[selectedContent[i + 1].id] && await axios.get(thumbnails[selectedContent[i + 1].id], {
                responseType: 'arraybuffer'
              })
                .then(res => res)
                .catch(res => res);

              const imageParamsB = {
                x: '55%',
                y: '30%',
                w: 8,
                h: 6
              };

              if (imageB && imageB.status >= 200 && imageB.status < 300) {
                imageParamsB.data = 'image/jpeg;base64,' + this.arrayBufferToBase64(imageB.data);
              } else {
                imageParamsB.path = './thumb_placeholder.jpg';
              }

              const tagsB = selectedContent[i + 1] ? selectedContent[i + 1].tags.map(t => t.name).join(', ') : '';
              contentSlide
                .addText(selectedContent[i + 1].name, {
                  x: '55%',
                  y: '20%',
                  w: '40%',
                  hyperlink: { url: selectedContent[i + 1].url }
                })
                .addText(tagsB, {
                  x: '55%',
                  y: "25%",
                  w: '35%'
                })
                .addImage(imageParamsB)
                .addText(selectedContent[i + 1].description, {
                  x: '55%',
                  y: 11.5,
                  w: '40%',
                });
            }
          }
        }

        // Slide(s) 6 - definitions
        for (let i = 0; i < this.selectedDefinitions.length; i = i + 2) {
          const youKnowPreamble = this.$t('glossary_you_know_preamble').split("<!!>");
          const defSlide = pres.addSlide();

          const thisDef1 = this.selectedDefinitions[i];

          const imageDefA = await axios.get(`/img/toptrumps/${thisDef1.category.toLowerCase()}/${thisDef1.genre
            .toLowerCase()
            .replace(/\W/g, '')}.jpg`, {
            responseType: 'arraybuffer'
          })
            .then(res => res)
            .catch(res => res);

          const imageDefParamsA = {
            x: '10%',
            y: '30%',
            w: 8,
            h: 6
          };

          if (imageDefA && imageDefA.status >= 200 && imageDefA.status < 300) {
            imageDefParamsA.data = 'image/jpeg;base64,' + this.arrayBufferToBase64(imageDefA.data);
          } else {
            imageDefParamsA.path = './thumb_placeholder.jpg';
          }

          defSlide
            .addText(this.$t('powerpoint_definition_description'), { x: '10%', y: '10%', fontSize: 22 })
            .addText(`${thisDef1.category}: ${thisDef1.genre}`, { x: '10%', y: '20%', w: '40%' })
            .addImage(imageDefParamsA)
            .addText(
              `${thisDef1.genre} ${thisDef1.description}\n\n${youKnowPreamble[0]}${thisDef1.genre}${youKnowPreamble[1]} ${thisDef1.youKnow}`,
              {
                x: '10%',
                y: 11.5,
                w: '40%',
              });

          if (this.selectedDefinitions[i + 1]) {
            const thisDef2 = this.selectedDefinitions[i + 1];
            const imageDefB = await axios.get(`/img/toptrumps/${thisDef2.category.toLowerCase()}/${thisDef2.genre
              .toLowerCase()
              .replace(/\W/g, '')}.jpg`, {
              responseType: 'arraybuffer'
            })
              .then(res => res)
              .catch(res => res);

            const imageDefParamsB = {
              x: '55%',
              y: '30%',
              w: 8,
              h: 6
            };

            if (imageDefB && imageDefB.status >= 200 && imageDefB.status < 300) {
              imageDefParamsB.data = 'image/jpeg;base64,' + this.arrayBufferToBase64(imageDefB.data);
            } else {
              imageDefParamsB.path = './thumb_placeholder.jpg';
            }

            defSlide
              .addText(`${thisDef2.category}: ${thisDef2.genre}`, { x: '55%', y: '20%', w: '40%' })
              .addImage(imageDefParamsB)
              .addText(
                `${thisDef2.genre} ${thisDef2.description}\n\n${youKnowPreamble[0]}${thisDef2.genre}${youKnowPreamble[1]} ${thisDef2.youKnow}`,
                {
                  x: '55%',
                  y: 11.5,
                  w: '40%',
                });
          }
        }

        await pres.writeFile();
        this.prepped = false;
        this.generating = false;
        window.paddleAPI = null;
        this.$bvToast.toast(this.$t('powerpoint_success_message'), {
          title: this.$t('common_success_title'),
          variant: 'success',
        });
        this.$bvModal.hide(`pptModal${this.series.id}`);
      } catch {
        this.prepped = false;
        this.generating = false;
        window.paddleAPI = null;
        this.step = 1;
        this.$bvToast.toast(
          this.$t('powerpoint_generation_fail'),
          {
            title: this.$t('common_error_title'),
            variant: 'danger',
          },
        );
      }
    },

    async getSurvey(survey) {
      try {
        const i = this.surveys.findIndex(s => s.id === survey.id);
        this.surveys[i] = await apiGet(`/survey/${survey.id}`);
      } catch {
        this.$bvToast.toast(
          this.$t('dashboard_survey_get_error', [survey.name]),
          {
            title: this.$t('common_error_title'),
            variant: 'danger',
          },
        );
      }
    },

    async svgToPng(svgString, width, height) {
      return new Promise(r => {
        var imgsrc = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        var image = new Image();
        image.onload = function () {
          context.clearRect(0, 0, width, height);
          context.drawImage(image, 0, 0, width, height);
          r(canvas.toDataURL());
        };
        image.src = imgsrc;
      })
    },
    arrayBufferToBase64(buffer) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    // The following turns disagree-to-agree questions into a 'degree of agree' percentage.
    // It will take the 5 options and create a weighted percentage that represents the data.

    // It could be genericised (i.e. remove hardcoded question answers) but this would require them
    // to be stored somewhere.

    // To demonstrate with extreme examples:
    // A question in which every respondent answered "disagree strongly" would return 0%;
    // if
    //  * half the respondents answered "disagree strongly" and half "agree strongly",
    //  * half the respondents answered "disagree somewhat" and half "agree somewhat", or
    //  * all respondents answered "neither agree nor disagree",
    // we would get 50%;
    // if they all answered "agree strongly" we would get 100%.

    /**
     * Turn attitues data into row for ppt table
     * @param {Object[]} input - input data from wheel
     * @param {string} input[].name - description/condensed version of question
     * @param {Object} input[].key - object maps answers to numerical keys in global and sub 
     * @param {Object} input[].global - dataset for global data
     * @param {Object} input[].sub - dataset for subset data
     * @returns {Array} unformatted data for ppt in row format
     */
    getAttitudeRow([name, key, global, sub]) {
      name = capitalize(name).trim();
      const global_pc = this.percentage(key, global);
      const sub_pc = this.percentage(key, sub);
      return [name, global_pc, sub_pc, sub_pc - global_pc];
    },

    /**
     * Calculate a percentage from a dataset
     * @param {Object} key - object maps answers to numerical keys in global and sub datasets
     * @param {Object} set - dataset
     * @returns {number} percentage for dataset
     */
    percentage(key, set) {
      const set_0 = set[key['DISAGREE STRONGLY']];
      const set_1 = set[key['DISAGREE SOMEWHAT']];
      const set_2 = set[key['NEITHER AGREE NOR DISAGREE']];
      const set_3 = set[key['AGREE SOMEWHAT']];
      const set_4 = set[key['AGREE STRONGLY']];
      return 25 * ((1 * set_0 + 2 * set_1 + 3 * set_2 + 4 * set_3 + 5 * set_4) - 1);
    },

    /**
     * Turn row from floating-point to nicely-formatted percentages
     * @param {Object[]} input - unformatted row
     * @param {string} input[].n - description/condensed version of question
     * @param {number} input[].g - global percentage
     * @param {number} input[].s - subset percentage
     * @param {number} input[].d - diff between global and subset
     * @returns {Array} formatted row for ppt
     */
    formatRow([n, g, s, d]) {
      return [n, `${Math.round(g) || 0}%`, `${Math.round(s) || 0}%`, `(${d > 0 ? '+' : ''}${Math.round(d) || 0})`];
    },

    // -------------------------
    // END AGREE QUESTIONS STUFF
    // -------------------------

    /**
     * Turn affinities data into row for ppt table
     * @param {Object[]} input - input data from wheel
     * @param {string} input[].name - thing for which affinity is being measured
     * @param {Object} input[].key - object maps answers to numerical keys in global and sub (always one value: 'SELECTED')
     * @param {Object} input[].global - dataset for global data
     * @param {Object} input[].sub - dataset for subset data
     * @returns {Array} unformatted data for ppt in row format
     */
    getAffinityRow([name, key, global, sub]) {
      if (name.search(/:/) > -1) {
        name = name.split(':')[1];
      }

      name = name.trim();
      name = capitalize(name)

      const global_pc = 100 * global[key['SELECTED']];
      const sub_pc = 100 * sub[key['SELECTED']];
      return [name, global_pc, sub_pc, sub_pc - global_pc];
    },


    /**
     * Turn other question data into row for ppt table
     * @param {Object[]} input - input data from wheel
     * @param {Object} input[].key - object maps answers to numerical keys in global and sub
     * @param {Object} input[].global - dataset for global data
     * @param {Object} input[].sub - dataset for subset data
     * @returns {Array} unformatted data for ppt in row format
     */
    getQuestionRows([, key, global, sub]) {
      return Object.keys(key).map(k => {
        const global_pc = 100 * global[key[k]];
        const sub_pc = 100 * sub[key[k]];

        if (k.search(/:/) > -1) {
          k = k.split(':')[1];
        }

        k = k.trim();
        k = capitalize(k);

        return [k, global_pc, sub_pc, sub_pc - global_pc];
      });
    },
    /**
     * Clear powerpoint setup values on close of modal
     */
    clearSetupValues() {
      this.step = 1;
      this.pptTitle = '';
      this.selectedContent = [];
      this.selectedDefinitions = [];
      this.analysis = null;
      this.recommending = false;
    },
  },
};
