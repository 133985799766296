/**
 * Content selector for PowerPoint generator.
 */

/* eslint-disable */
import Icon from '@/icons/Icon.vue';
import { apiGet } from '@/util/api';
import ContentCard from '@/components/ContentCard.vue';
import RecommendationEngine from '@/util/recommendationEngine';

export default {
  name: 'QueriesContentSelector',
  components: {
    Icon,
    ContentCard
  },
  props: {
    initSelectedContents: Array,
    tags: Array,
    series: Object,
  },
  data() {
    return {
      loading: true,

      mappedTags: [],

      contents: [],

      // categoryTags: [],
      // category: null,
      genreTags: [],
      genre: null,
      countryTags: [],
      country: null,

      filter: '',
      contentType: 'all',
      recommendedContents: [],
      analysis: null,
      seriesContainsCountry: false,
      noCountry: false,


      selectedContentsTablePerPage: 5,
      selectedContentsTableCurrentPage: 1,

      contentsTablePerPage: 5,
      contentsTableCurrentPage: 1,
      contentsTableFields: [
        {
          key: 'name',
          label: this.$t('contents_list_content_name'),
          thStyle: { width: '45%' },
        },
        {
          key: 'tags',
          label: this.$t('powerpoint_contents_list_content_tags'),
          thStyle: { width: '40%' },
        },
        {
          key: 'selected',
          label: '',
          class: 'table-user-control',
          thStyle: { width: '15%' },
        },
      ],
    }
  },
  async created() {
    this.seriesContainsCountry = JSON.parse(this.series.series).query.some(q => q[0] === 'COUNTRY');
    this.mappedTags = this.tags.map(t => ({ ...t, parentTag: this.tags.find(pt => t.parentTag === pt.id) }));

    const tagsForSelect = this.mappedTags.map(t => ({ text: t.name, value: t.id, category: t.category, parentTagName: t.parentTag && t.parentTag.name }));
    this.countryTags = tagsForSelect.filter(t => t.category === 'COUNTRY');
    this.countryTags.unshift({ text: this.$t('powerpoint_content_filter_country'), value: null, disabled: true });

    const genreTagsFunny = tagsForSelect
      .filter(t => t.category === 'GENRE' && t.parentTagName === 'Funny')
      .map(t => ({ ...t, text: 'Funny: ' + t.text }));
    const genreTagsUseful = tagsForSelect
      .filter(t => t.category === 'GENRE' && t.parentTagName === 'Useful')
      .map(t => ({ ...t, text: 'Useful: ' + t.text }));
    const genreTagsBeautiful = tagsForSelect
      .filter(t => t.category === 'GENRE' && t.parentTagName === 'Beautiful')
      .map(t => ({ ...t, text: 'Beautiful: ' + t.text }));
    const genreTagsInspiring = tagsForSelect
      .filter(t => t.category === 'GENRE' && t.parentTagName === 'Inspiring')
      .map(t => ({ ...t, text: 'Inspiring: ' + t.text }));

    this.genreTags = [...genreTagsFunny, ...genreTagsUseful, ...genreTagsBeautiful, ...genreTagsInspiring];
    this.genreTags.unshift({ text: this.$t('powerpoint_content_filter_genre'), value: null, disabled: true });

    this.contents = (await apiGet(`/content?thumbnails=false&withTags=true`))
      .map(content => ({
        ...content,
        selected: this.initSelectedContents && this.initSelectedContents.some(c2 => content.id === c2.id),
        tags: content.tags.map(t1 => this.mappedTags.find(t2 => t1.id === t2.id))
      }))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    this.loading = false;

  },
  watch: {
    noCountry: function () {
      this.setRecommended();
    }
  },
  computed: {
    contentsList: function () {
      let contents = this.contents;

      if (this.contentType === 'bookmarks') {
        contents = contents.filter(c => this.$store.state.user.bookmarks.indexOf(c.id) > -1);
      } else if (this.contentType === 'recommended') {
        if (this.recommendedContents) {
          contents = [...this.recommendedContents];
        }
      }

      contents = contents.filter(c => {
        return (!this.country || c.tags.some(t => t.id === this.country))
          && (!this.genre || c.tags.some(t => t.id === this.genre))
      });

      if (!this.filter) return contents;

      const filter = this.filter.toLowerCase().split(' ');

      return contents.filter(c => {
        let query = `${c.name.toLowerCase()}${c.description.toLowerCase()}}`;
        query = query.replace(/\s/g, '');

        return filter.every(word => query.includes(word.trim()));
      });
    },
    selectedContents: function () {
      const selectedContents = this.contents.filter(c => c.selected);
      this.$emit('change-contents', { contents: selectedContents });
      return selectedContents;
    }
  },
  methods: {
    changeContentType(type) {
      if (type === 'recommended') {
        if (this.recommendedContents.length === 0) {
          this.loading = true;
        }
        this.$emit('analyse');
      }
    },
    flipSelected(data) {
      data.item.selected = !data.item.selected;

      if (this.selectedContentsTableCurrentPage > Math.ceil(this.selectedContents.length / this.selectedContentsTablePerPage)) {
        this.selectedContentsTableCurrentPage = Math.ceil(this.selectedContents.length / this.selectedContentsTablePerPage);
      }
    },
    resetPagination() {
      this.contentsTableCurrentPage = 1;
    },
    clearGenreFilter() {
      this.resetPagination();
      this.genre = null;
    },
    clearCountryFilter() {
      this.resetPagination();
      this.country = null;
    },

    /**
     * Clear the content filter variable.
     */
    clearFilter() {
      this.filter = '';
      this.resetPagination();
    },
    async setRecommended(analysisPromise) {
      if (analysisPromise)
        this.analysis = await analysisPromise;
      const engine = new RecommendationEngine(
        Object.fromEntries(
          this.analysis.fubi_labels.map((_, i) => [
            this.analysis.fubi_labels[i],
            this.noCountry ? this.analysis.fubi_histogram_global[i] : this.analysis.fubi_histogram[i],
          ]),
        ),
      );
      let lContents = [...this.contents];
      engine.populateScores(lContents);
      lContents = lContents.sort((a, b) => b.score - a.score);
      lContents = lContents.filter(
        c => c.tags && c.tags.filter(t => t.category === 'GENRE').length > 1,
      );
      this.recommendedContents = lContents.slice(0, 20);
      this.loading = false;
    },
  },
};
